import { ThemeProvider } from 'styled-components';
import GlobalStyles from './estilos/GlobalStyles';
import light from './estilos/themes/light';
import RoutesProfile from './controle-rotas';
import { Toaster } from 'react-hot-toast';
import { ModalProvider } from "styled-react-modal";

function App() {

  return (
    <div>
      <GlobalStyles/>
      <Toaster position="top-center" reverseOrder={false} />
      <ModalProvider>
        <RoutesProfile/>
      </ModalProvider>
    </div>
  );
}

export default App;
