import axios from 'axios'

let httpCliente = axios.create({
    baseURL: `${process.env.REACT_APP_URL_BACKEND}`,
    withCredentials:true
})

class ApiService {
    constructor(apiurl){
        this.apiurl=apiurl
    }

    post(url,obj){
        const requestURL = `${this.apiurl}${url}`
        return httpCliente.post(requestURL,obj,{
            headers: {'Content-Type': 'application/json'}
        })
    }

    put(url,obj){
        const requestURL = `${this.apiurl}${url}`
        return httpCliente.put(requestURL,obj,{
            headers: {'Content-Type': 'application/json'}
        })
    }

    delete(url){
        const requestURL = `${this.apiurl}${url}`
        return httpCliente.delete(requestURL)
    }

    get(url){
        const requestURL = `${this.apiurl}${url}`
        return httpCliente.get(requestURL)
    }
}

export default ApiService