import React, { useEffect, useState } from 'react'
import { getNivelPermissaoCookie , Auth_login , getNomeNivelPermissao, getNomeCliente } from '../../utils'
import { Container, Content, ContentGif } from './styles'
import Cookie from "js-cookie"
import HeaderHomeAdm from '../../componentes/header-home-adm'
import ConnectLogin from '../../apis/login'
import { Route } from 'react-router-dom'
import gif from '../../imagens/CarregandoDashPSAi.gif'
import { Img } from '../../componentes/utilitarios'
import ModalTrocarSenha from '../../componentes/modal-trocarsenha'
import FooterHomeAdm from '../../componentes/footer-home-adm'
import ContaBI from '../contabi'
import AreaNegocio from '../areanegocio'
import Painel from '../painel'
import Usuario from '../usuarioAdm'
import ClienteAdm from '../clienteAdm'
import PaineisCliente from '../vincularPaineisCliente'
import PaineisUsuario from '../vincularPaineisUsuario'

const HomeAdm = (props)=>{

    const serviceLogin = new ConnectLogin()

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [paginaAtiva,setPaginaAtiva] = useState(null)

    useEffect(()=>{
        const nivelpermissao = getNivelPermissaoCookie()
        if(!Auth_login()){
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
        else if(nivelpermissao===1){
            window.location.href="/admcliente"
        }
        else if(nivelpermissao===2){
            window.location.href="/cliente"
        }
    },[])

    useEffect(async()=>{
        try{
            let result = await serviceLogin.verificarVivo()
            if(result.status===401){
                Cookie.remove('connect.sid')
                Cookie.remove('usuario.sid')
                Cookie.remove('perm.sid')
                Cookie.remove('areas.sid')
                Cookie.remove('paineis.sid')
                window.location.href="/"
            }
        }
        catch(error){
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
    },[HomeAdm])

    useEffect(()=>{
        if(paginaAtiva===1){
            props.history.push('/adm/areanegocio')
        }
        else if(paginaAtiva===2){
            props.history.push('/adm/contabi')
        }
        else if(paginaAtiva===3){
            props.history.push('/adm/painel')
        }
        else if(paginaAtiva===4){
            props.history.push('/adm/cliente')
        }
        else if(paginaAtiva===5){
            props.history.push('/adm/usuario')
        }
    },[paginaAtiva])

    const deslogar = async() =>{
        try{
            await serviceLogin.deslogar()
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
        catch(error){
            Cookie.remove('connect.sid')
            Cookie.remove('usuario.sid')
            Cookie.remove('perm.sid')
            Cookie.remove('areas.sid')
            Cookie.remove('paineis.sid')
            window.location.href="/"
        }
    }

    const abrirModalTrocarSenha = () =>{
        setModalIsOpen(!modalIsOpen)
    }

    const carregamentoGif = () =>{
        if(paginaAtiva===null){
            return(
                <ContentGif>
                    <Img src={gif} width="200px" height="200px"/>
                </ContentGif>
            )
        }
    }

    return(
        <Container>
            <HeaderHomeAdm deslogar={deslogar} nomeusuario={getNomeCliente()} nivelpermissao={getNomeNivelPermissao()}
                TrocarSenha={abrirModalTrocarSenha}/>
            <Content>
                {carregamentoGif()}
                <Route path="/adm/areanegocio"                render={(props) => <AreaNegocio {...props} paginaAtiva={paginaAtiva}/>}/>
                <Route path="/adm/contabi"                    render={(props) => <ContaBI {...props} paginaAtiva={paginaAtiva}/>}/>
                <Route path="/adm/painel"                     render={(props) => <Painel {...props} paginaAtiva={paginaAtiva}/>}/>
                <Route path="/adm/cliente"                    render={(props) => <ClienteAdm {...props} paginaAtiva={paginaAtiva}/>}/>
                <Route path="/adm/usuario/:idcliente"         render={(props) => <Usuario {...props} paginaAtiva={paginaAtiva}/>}/>
                <Route path="/adm/paineiscliente/:idcliente"  render={(props) => <PaineisCliente {...props} paginaAtiva={paginaAtiva}/>}/>
                <Route path="/adm/paineisusuario/:idusuario"  render={(props) => <PaineisUsuario {...props} paginaAtiva={paginaAtiva}/>}/>
            </Content>
            <ModalTrocarSenha
                setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
            />
            <FooterHomeAdm setPaginaAtiva={setPaginaAtiva} paginaAtiva={paginaAtiva} deveDesabilitar={false}/>
        </Container>
    )
}

export default HomeAdm