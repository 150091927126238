import React, { useEffect } from 'react'
import { getNivelPermissaoCookie , Auth_login } from '../utils'

const Redirecionador = (props)=>{

    useEffect(()=>{
        if(Auth_login()){
            const nivelpermissao = getNivelPermissaoCookie()
            if(nivelpermissao===0){
                window.location.href="/adm"
            }
            else if(nivelpermissao===1){
                window.location.href="/admcliente"
            }
            else if(nivelpermissao===2){
                window.location.href="/cliente"
            }
        }
        else{
            window.location.href="/"
        }
    },[])

    return(
        <div>

        </div>
    )
}

export default Redirecionador